// import { Component, OnInit, AfterViewInit } from "@angular/core";
// import { HttpClient } from "@angular/common/http";
// import { Router } from "@angular/router";
// import { UserService, SharedService, CartDataService } from "../../services";
// import { API_SERVICE_CONFIG } from "../../config/api-service.config";

// declare var $: any;

// @Component({
//   selector: "app-first",
//   templateUrl: "./first.component.html",
//   styleUrls: ["./first.component.scss"],
// })
// export class FirstComponent implements OnInit, AfterViewInit {
//   loading = false;
//   ebookImages = [];
//   packageImages = [];
//   softwareImages = [];
//   imgUrl: string;
//   groupedEbookImages: any[] = [];
//   groupedPackageImages: any[] = [];
//   groupedSoftwareImages: any[] = [];

//   constructor(
//     private http: HttpClient,
//     private router: Router,
//     private shared: SharedService
//   ) {}

//   ngOnInit() {
//     this.fetchCarouselImages();
//   }

//   ngAfterViewInit() {
//     $(".modal").on("hide.bs.modal", () => {
//       $("#ytplayer").attr("src", "");
//     });
//   }

//   fetchCarouselImages() {
//     this.loading = true;
//     this.http
//       .get(
//         "https://coasttocoastofficesupply.com/admin/public/api/v1/sliding-image-data"
//       )
//       .subscribe(
//         (response: any) => {
//           this.loading = false;
//           this.ebookImages = response.success.ebook_images;
//           this.packageImages = response.success.package_images;
//           this.softwareImages = response.success.software_images;
//           this.groupImages();
//         },
//         (error) => {
//           this.loading = false;
//           console.error("Error fetching carousel images:", error);
//         }
//       );
//   }

//   groupImages() {
//     this.groupedEbookImages = [];
//     this.groupedPackageImages = [];
//     this.groupedSoftwareImages = [];

//     for (let i = 0; i < this.ebookImages.length; i += 6) {
//       this.groupedEbookImages.push(this.ebookImages.slice(i, i + 6));
//     }

//     for (let i = 0; i < this.packageImages.length; i += 6) {
//       this.groupedPackageImages.push(this.packageImages.slice(i, i + 6));
//     }

//     for (let i = 0; i < this.softwareImages.length; i += 6) {
//       this.groupedSoftwareImages.push(this.softwareImages.slice(i, i + 6));
//     }
//   }

//   getImageUrl(imagePath: string): string {
//     return `https://coasttocoastofficesupply.com/admin/public${imagePath}`;
//   }

//   productCategory(value: string) {
//     localStorage.setItem("selectedCategories", JSON.stringify([value]));
//     this.router.navigate(["category/downloadable", { category_id: value }]);
//   }
// }

import { Component, OnInit, AfterViewInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { UserService, SharedService, CartDataService } from "../../services";
import { API_SERVICE_CONFIG } from "../../config/api-service.config";

declare var $: any;

@Component({
  selector: "app-first",
  templateUrl: "./first.component.html",
  styleUrls: ["./first.component.scss"],
})
export class FirstComponent implements OnInit, AfterViewInit {
  loading = false;
  ebookImages = [];
  packageImages = [];
  softwareImages = [];
  imgUrl: string;
  groupedEbookImages: any[] = [];
  groupedPackageImages: any[] = [];
  groupedSoftwareImages: any[] = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private shared: SharedService
  ) {}

  ngOnInit() {
    this.fetchCarouselImages();
  }

  ngAfterViewInit() {
    $(".modal").on("hide.bs.modal", () => {
      $("#ytplayer").attr("src", "");
    });
  }

  fetchCarouselImages() {
    this.loading = true;
    this.http
      .get(
        "https://coasttocoastofficesupply.com/admin/public/api/v1/sliding-image-data"
      )
      .subscribe(
        (response: any) => {
          this.loading = false;
          this.ebookImages = response.success.ebook_images;
          this.packageImages = response.success.package_images;
          this.softwareImages = response.success.software_images;
          this.groupImages();
        },
        (error) => {
          this.loading = false;
          console.error("Error fetching carousel images:", error);
        }
      );
  }

  groupImages() {
    this.groupedEbookImages = [];
    this.groupedPackageImages = [];
    this.groupedSoftwareImages = [];

    for (let i = 0; i < this.ebookImages.length; i += 6) {
      this.groupedEbookImages.push(this.ebookImages.slice(i, i + 6));
    }

    for (let i = 0; i < this.packageImages.length; i += 6) {
      this.groupedPackageImages.push(this.packageImages.slice(i, i + 6));
    }

    for (let i = 0; i < this.softwareImages.length; i += 6) {
      this.groupedSoftwareImages.push(this.softwareImages.slice(i, i + 6));
    }
  }

  getImageUrl(imagePath: string): string {
    return `https://coasttocoastofficesupply.com/admin/public${imagePath}`;
  }

  productCategory(value: string) {
    localStorage.setItem("selectedCategories", JSON.stringify([value]));
    this.router.navigate(["category/downloadable", { category_id: value }]);
  }
}
